import { useEffect, useState } from "react";


import './stories.scss'
import { useAppDispatch, useAppSelector } from "./hooks";
import { onCloseStory, selectCurrentVideo,incrementVideoIndex, selectCurrentStory, selectCurrentStoryIcon, StoriesVideoItem, selectCurrentVideoIndex, updateVideoProgress, selectCurrentVideoPercent, selectCurrentVideoDuration } from "./features/stories/StoriesSlice";
import { duration } from "moment";
export interface SplashProp {
    children: React.ReactNode
}
export interface VideoProgressBarProps{
    videos:StoriesVideoItem[]
    currentVideoIndex:number
    currentVideoPercent:number
    currentVideoDuration:number

}
const VideoProgressBar = (props:VideoProgressBarProps) => {
    const getProgressWidth = (index:number) => {
      if (index < props.currentVideoIndex) {
        return "100%";
      }
      if (index > props.currentVideoIndex) {
        return "0%";
      }
      // Для текущего видео вычисляем процент прогресса
      // return `${props.currentVideoPercent}%`;
      return "100%";
    };
    // let [currentW,setCurrentW] = useState<number>(0)
    // useEffect(()=>{
    //   setCurrentW(100)
    // })
    return (
      <div className="videoProgressBar">
        {props.videos.map((video, index) => (
          <div className="videoItemProgressBar" key={video.path}>
            <div
              className="videoItemProgressBarContent"
              style={{ 
                width:  getProgressWidth(index),
                // width: index == props.currentVideoIndex ? currentW+'%' : getProgressWidth(index),
                // transition: index === props.currentVideoIndex ? `width ${props.currentVideoDuration}s linear` : "none",

               }}
            ></div>
          </div>
        ))}
      </div>
    );
  };
  
export function Stories(props: SplashProp) {
    const dispatch = useAppDispatch();
    let currentStory = useAppSelector(selectCurrentStory)
    let currentStoryIndex = useAppSelector(selectCurrentVideoIndex)
    let currentVideoPercent= useAppSelector(selectCurrentVideoPercent)
    let currentStoryVideo = useAppSelector(selectCurrentVideo)
    let currentStoryIcon = useAppSelector(selectCurrentStoryIcon)
    const onClickScreeonVideoEndedn = () => {
        dispatch(incrementVideoIndex(currentStoryIndex))

    }
    const onProgress = (e: any) => {
        const currentTime = e.target.currentTime; 
        const duration = currentStoryVideo?.duration??1;
        console.log(`Process ${currentTime},${duration},${currentTime/duration}`)
        dispatch(updateVideoProgress({
            progress: currentTime,
            duration: duration,
        }))
    }
    let isShowStory = currentStoryVideo != null
    const onCloseStoryHandler = () => {
        dispatch(onCloseStory())
    }
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(incrementVideoIndex(currentStoryIndex))
    }

    useEffect(() => {
        let splashVideo = document.getElementById('storyVideo')
        splashVideo?.addEventListener('ended', () => {
            onClickScreeonVideoEndedn()
        })
    })

    return (
        <>
            {
                isShowStory ? (
                    <>
                        {props.children}
                         <div className="storyVideoDialogBg" ></div>
                        <div className="storyVideoDialog" >
                         <VideoProgressBar
                          videos={currentStory?.videos??[]} 
                         currentVideoDuration={currentStoryVideo?.duration??1} 
                         currentVideoIndex={currentStoryIndex} 
                        currentVideoPercent={currentVideoPercent} 
                         />
                          
                            <video id="storyVideo"
                                key={currentStoryVideo?.path??'key'}
                                onClick={onClickScreen}
                                onEnded={onClickScreeonVideoEndedn}
                                onProgress={onProgress}
                                autoPlay
                                 muted className="storyVideo"
                            >
                                <source
                                    className="footer-video_content"
                                    src={currentStoryVideo?.path ?? ''}
                                    type='video/mp4' />
                            </video>
                            <img src="assets/img/icons/close_white.svg" onClick={onCloseStoryHandler} alt="" className="close_icon" />
                           <div className="stories-header">
                              <div className="stories-avatar">
                                <img src={currentStoryIcon??""} alt="" />
                                </div>
                                <div className="stories-name">{currentStory?.name??""}</div>
                           </div>
                        </div>


                    </>
                ) : (
                    <>
                        {props.children}
                    </>
                )
            }
        </>

    )
}
