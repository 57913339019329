
import './support.scss';
interface SupportProps{
    supportPhone:string 
}
export function Support(props:SupportProps){
    return (
        <div className='support_line'>
            <div>Служба поддержки:&nbsp;&nbsp;&nbsp; </div>
            <div className='support_phone'>{props.supportPhone} </div>
        </div>
    )
}
