import { useHistory } from "react-router";
import { TopLine } from '../../components/top-line/TopLine';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectPageCategories, CategoryLoadStatus, onNextPage, selectCategoriesLoadStatus, onChangeHowItWorksWrapperShow, selectHowItWorksWrapperShow, selectSimProductId, selectCategory, selectIsNeedNextPage, selectDefaultQr, selectDefaultQrIndex, selectSelectPromoData, selectDefaultQrUsedIndex, selectBuyCount, selectRepairCount } from "./homeSlice";

import './home.scss';
import { useEffect } from "react";
import { selectLoadingStatusState, selectOrderStatusState } from "../order-status/orderStatusSlice";
import { Loading } from "../../components/loading/Loading";
import { HowItWorks } from "../how-it-works/HowItWorks";
import { onCloseAll } from "../how-it-works/howItWorksSlice";
import userSmartHistory from "../../metrica/metrica";
import { WaitReceipt } from "../../components/wait-receipt/WaitReceipt";
import { Category } from "../../sdk/client/model";
import { isSaled } from "../product-page/productPageSlice";
import { qrAction } from "../../store/qrmid";
import { openPromoWindow, openSaleWindow } from "../saled-window/saledWindowSlice";



export function Home() {
  
    const howItWorksWrapperShow = useAppSelector(selectHowItWorksWrapperShow);
    let navigate = userSmartHistory();
    const dispatch = useAppDispatch();

    let categories = useAppSelector(selectPageCategories);

    let onClickCategoryHandler = (category: Category) => {
        dispatch(selectCategory(category))
        if (!category.disabled) {
            navigate.push(`/category/${category.id}/products`);
        }
    }
    const categoriesLoadStatus = useAppSelector(selectCategoriesLoadStatus)
    const isNeedNextPage = useAppSelector(selectIsNeedNextPage)
    let isSaledValue = useAppSelector(isSaled);
    let promoData = useAppSelector(selectSelectPromoData);
    let defaultQrIndex = useAppSelector(selectDefaultQrIndex);
    let usedQrIndex = useAppSelector(selectDefaultQrUsedIndex);
    let buyCount = useAppSelector(selectBuyCount);
    let repairCount = useAppSelector(selectRepairCount);
    useEffect(() => {
        console.log(`Promo screen: ${promoData?.default_qr} - ${defaultQrIndex}`)
        if (usedQrIndex != defaultQrIndex) {
            setTimeout(() => {
                if (promoData != null) {
                    let qrActionItem = openPromoWindow({ type: 'promo', index: defaultQrIndex })
                    dispatch(qrActionItem)
                }
            }, 500)
        } else {
            console.log(`Skip by windows ${usedQrIndex}`)
        }
    }, [promoData?.default_qr, defaultQrIndex])

    if (categoriesLoadStatus == CategoryLoadStatus.Loading) {
        return <Loading />
    }

    return (<>

        {howItWorksWrapperShow ? (
            <HowItWorks onChangeHowItWorksWrapperShow={() => { dispatch(onChangeHowItWorksWrapperShow()) }}></HowItWorks>
        ) : null}

        <section className="homepage-counters">
            <div className="counter"> <img className="stat_image" src="/assets/img/stat_image.png" alt="" /> </div>
            <div className="counter">

                <div className="counter-count">{repairCount ?? '-'}</div>
                <div className="counter-title">оказано услуг </div>
            </div>
            <div className="counter">
                <div className="counter-count">{buyCount ?? "-"}</div>
                <div className="counter-title">куплено товаров</div>
            </div>
        </section>
        <section className="homepage-buttons">
            <div className="container">
                <button onClick={() => {

                    navigate.push('/repair-options')

                }} className="homepage-button first">
                    <span className="homepage-button_text">Отправить <br />в ремонт</span>
                </button>
                <button onClick={() => {
                    navigate.push('/qr-scan')
                }} className="homepage-button second">
                    <span className="homepage-button_text">Забрать <br />из ремонта</span>
                </button>
            </div>
        </section>
        <div className="border-test">
            <div className="buy-here">
                <div className="title">
                    Здесь можно купить
                </div>
                <div className="subtitle">
                    Выберите необходимый аксессуар
                </div>
            </div>

            <section className="categories-list">
                <div className="container">
                    {categories.map((c: Category) =>
                        <div key={c.id} className={`item-card category-card ${c.disabled == true ? ' disabled' : ''}`} onClick={() => { onClickCategoryHandler(c) }}>
                            <span className="item-card_title">{c.name}</span>
                            <div className={`item-card-price-block ${isSaledValue ? 'sale' : ''}`}>
                                <div className="item-card_price_sale">от {c.min_discount_price}
                                    <span className="item-card_symbol">₽</span>
                                </div>
                                <img className='crossSale' src="/assets/img/pricecross.svg" />
                                <div className="item-card_price">от {c.min_price}₽</div>
                            </div>
                            <img src={c.img} alt="" className="item-card_pic" />

                        </div>
                    )}
                    {
                        isNeedNextPage ?
                            (<div className={`item-card category-card next`} onClick={() => { dispatch(onNextPage()) }}>
                                <img src="assets/img/nav_next.png" alt="" />
                            </div>) : (<></>)
                    }

                </div>
            </section>
            <section className="homepage-action">
                <div className="action-item">

                    <div className="action-title">
                        <span>Акция! </span>
                        <br />
                        <br />
                        При любом ремонте  </div>
                    <div className="action-subtitle">Чистка от пыли
                        <br />
                        и грязи БЕСПЛАТНО</div>
                </div>
                <div className="action-item"> <img className="stat_image" src="/assets/img/homepage-action.png" alt="" /> </div>

            </section>
        </div>
    </>)

}
