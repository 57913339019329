import { useEffect, useState } from "react";


import './splash.scss'
import { useAppDispatch, useAppSelector } from "./hooks";
import { changeVideoIndex, selectVideoIndex } from "./features/home/homeSlice";
import { changeSplashIndex, selectLastTouchTime, selectSplashIndex } from "./touchSlice";
export interface SplashProp {
    children: React.ReactNode
}
var splashTimeout = 60000 * 2
// var splashTimeout = 20*60000 * 2
var splashTimeoutShow = 60000 * 2 


export function Splash(props: SplashProp) {

    let lastTouchValue = useAppSelector(selectLastTouchTime) ?? 0
    let splashIndex = useAppSelector(selectSplashIndex) 
    let [value, setValue] = useState(false)
    const dispatch = useAppDispatch();
    const onClickScreeonVideoEndedn = () => {
        dispatch(changeVideoIndex())
       
    }
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setValue(false)
    }

    useEffect(() => {
        let splashVideo = document.getElementById('splashVideo')
        splashVideo?.addEventListener('ended', () => {
            onClickScreeonVideoEndedn()
        })
    })


    useEffect(() => {
        console.log("DEBUG: splash start")
        if (document.location.pathname == '/') {
            const interval = setTimeout(() => {
                if (document.location.pathname == '/') {
                    setValue(true)
                }
            }, splashTimeoutShow);
            return () => {
                clearInterval(interval)
                console.log("DEBUG: splash stop")
            };
        }else{
            return () => {}
        }
      
    }, [lastTouchValue,splashIndex]);
     useEffect(() => {
    
        console.log("DEBUG: waiter start")
        if(value){
            const interval = setTimeout(() => {
                if(value){
                    setValue(false)
                    dispatch(changeSplashIndex())
                }
            }, splashTimeout);
            return () => {
                clearInterval(interval)
                console.log("DEBUG: wait video stop")
            };
        }else{
            return () => {}
        }
      
    }, [value]);
    console.log( `Splash log: ${lastTouchValue}`)


    return (
        <>
            {
                value ? (
                    <video id="splashVideo"
                        onClick={onClickScreen}
                        onEnded={onClickScreeonVideoEndedn}
                        autoPlay loop muted className="splashVideo"
                    >
                        <source
                            className="footer-video_content"
                            src={`/assets/videos/splash/splash${splashIndex}.mp4`}
                            type='video/mp4' />
                    </video>
                ) : (
                    <div >
                        {props.children}
                    </div>
                )
            }
        </>

    )
}
